import {CREATE_GIFTCARD,CREATE_GIFTCARD_MULTIPLE_REQUEST,FETCH_GIFTCARD_SESSION} from "@/store/actions.type";
import {SET_PAYMENT_FORM,SET_GIFTCARD_MULTIPLE_REQUEST,SET_GIFTCARD_SESSION} from "@/store/mutations.type";
import {CreateGiftcardService,CreateGiftcardMultiRequestService,FetchPayerSessionService} from "@/core/services/api";

const actions = {
    [FETCH_GIFTCARD_SESSION]({ commit }, params) {
        return FetchPayerSessionService.get(params)
            .then(({ data }) => {
                commit(SET_GIFTCARD_SESSION, data);
            })
            .catch(error => {
                commit(SET_GIFTCARD_SESSION, null);
                return error;
            });
    },
    [CREATE_GIFTCARD]({ commit }, params) {
        return CreateGiftcardService.get(params)
            .then(({ data }) => {
                commit(SET_PAYMENT_FORM, data);
            })
            .catch(error => {
                commit(SET_PAYMENT_FORM, null);
                return error;
            });
    },
    [CREATE_GIFTCARD_MULTIPLE_REQUEST]({ commit }, params) {
        return CreateGiftcardMultiRequestService.get(params)
            .then(({ data }) => {
                commit(SET_GIFTCARD_MULTIPLE_REQUEST, data);
            })
            .catch(error => {
                commit(SET_GIFTCARD_MULTIPLE_REQUEST, null);
                return error;
            });
    }
}

export default actions;