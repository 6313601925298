<template>
  <div id="spk-plugin">
    <div>
      <main>
        <div class="container-bokahem mt-2" v-if="headingTitle ">
          <div class="calendar-bokahem">
            <div class="calendar-bokahem-body">
              <h2 id="smartapresentkort_header_top">{{ headingTitle }}</h2>
            </div>
          </div>
        </div>
        <div class="container-bokahem mt-2" v-if="showInterestForm === 1">
          <div class="calendar-bokahem">
            <div class="calendar-bokahem-body">
                <h4>Är ni ett företag och vill köpa fler än 10 presentkort?</h4>
                <a style="text-decoration: underline;cursor: pointer;" @click="openRequestForm()" v-if="showRequestForm === false">Kontakta oss</a>
                <a style="text-decoration: underline;cursor: pointer;" @click="openRequestForm()" v-if="showRequestForm === true">Tillbaka</a>
            </div>
          </div>
        </div>
        <GiftcardMultiSellForm v-if="showRequestForm === true && showInterestForm === 1"></GiftcardMultiSellForm>
        <GiftcardSellForm :paymentError="responseErrorMode" v-if="(sellFormMode === true && showRequestForm === false)" ></GiftcardSellForm>
        <PaymentSuccess v-if="responseSuccessMode === true"></PaymentSuccess>
      </main>
      <modals-container/>
    </div>
  </div>
</template>

<script>

  import './assets/scss/style.scss';
  import './assets/custom-svg/sprites.svg';

  import GiftcardMultiSellForm from "@/pages/GiftcardMultiSellForm";
  import GiftcardSellForm from "@/pages/GiftcardSellForm";
  import PaymentSuccess from "@/pages/PaymentSuccess";

  import { FETCH_PLUGIN_SETTINGS, FETCH_GIFTCARD_SESSION } from "@/store/actions.type";

  export default {
    name: 'App',
    data() {
      return {
        responseSuccessMode: false,
        responseErrorMode: false,
        sellFormMode: false,
        showRequestForm: false
      }
    },
    methods: {
      openRequestForm() {
        this.showRequestForm = !this.showRequestForm;
      }
    },
    computed: {
      showInterestForm() {
        return this.$store.getters.showInterestForm;
      },
      sessionFirstname() {
        return this.$store.getters.sessionFirstname;
      },
      headingTitle() {
        return this.$store.getters.headingTitle;
      },
    },
    mounted() {
      this.$store.dispatch(FETCH_PLUGIN_SETTINGS).then(() => {
        
      /**
      * Check query parameters
      */
      let query = this.$router.history.current.query;

      if ('spkp' in query) {

        const query = Object.assign({}, this.$route.query);

        let spkp = query.spkp;

        delete query.spkp;
        this.$router.replace({ query });

        if (spkp == '0') {
          this.responseErrorMode = true;
          this.sellFormMode = true;
          setTimeout(() => {
                this.$scrollTo('#smartapresentkort_header_top');
          }, 700);
        } else {

          if (this.$store.getters.externalRedirectLink !== null 
              && this.$store.getters.externalRedirectLink !== '') {
                let seconds = this.$store.getters.secondsBeforeRedirect;
                let timeoutMilliseconds = seconds * 1000; 
                let urlToRedirect = this.$store.getters.externalRedirectLink;

                setTimeout(function () {
                  location.href = urlToRedirect;
                }, 
                  timeoutMilliseconds
                );
          } 

          this.$store.dispatch(FETCH_GIFTCARD_SESSION, spkp).then(() => {
            if (this.$store.getters.sessionFirstname !== null) {
              this.responseSuccessMode = true;
              setTimeout(() => {
                this.$scrollTo('#smartapresentkort_header_top');
              }, 700);
            } else {
              this.sellFormMode = true;
            }
          })
        }

      } else {
        this.sellFormMode = true;
      }
 /**
         * Remove WP classes
         */
         setTimeout(function () {

          Array.from(document.querySelectorAll('.info-block-bokahem-input.form-control')).forEach(function(el) {
            el.classList.remove('form-control');
          });

          Array.from(document.querySelectorAll('.info-input-bokahem-holder.form-group')).forEach(function(el) {
            el.classList.remove('form-group');
          });

          }, 170);
       
      });              
    },
    components: {
      GiftcardSellForm,
      PaymentSuccess,
      GiftcardMultiSellForm
    }
  }
</script>
