<template>
  <main>
    <div class="container-bokahem">
      <div class="calendar-bokahem mb-4">
        <div class="calendar-bokahem-body">
          <div>
            <SellerLogo></SellerLogo>
            <div class="row-bokahem">
              <div class="col-bokahem-12 mt-1">
                <fieldset class="form-group-bokahem check_border_bokahem">
                  <legend class="font-weight-medium-bokahem mb-0">
                    Tack för ditt köp av presentkort!
                  </legend>
                  <div class="m-auto-bokahem mt-1">
                    Inom några minuter kommer du få ett mejl med presentkort. Om du inte hittar det titta gärna i skräpposten.
                  </div>
                  <div class="m-auto-bokahem mt-1 mb-1">
                    Dina uppgifter:
                  </div>
                  <div class="m-auto-bokahem" v-if="sessionAmount">
                    Presentkort: {{ sessionAmount }} kr
                  </div>
                  <div class="m-auto-bokahem" v-if="sessionQuantity">
                    Antal: {{ sessionQuantity }}
                  </div>
                  <div class="m-auto-bokahem" v-if="sessionFirstname">
                    Förnamn: {{ sessionFirstname }}
                  </div>
                  <div class="m-auto-bokahem" v-if="sessionLastname">
                    Efternamn: {{ sessionLastname }}
                  </div>
                  <div class="m-auto-bokahem" v-if="sessionEmail">
                   E-post: {{ sessionEmail }}
                  </div>

                  <div class="m-auto-bokahem mt-1">
                    Har du några frågor kontakta oss gärna!
                  </div>

                  <div class="m-auto-bokahem mt-1">
                    {{ giftcardSellerName }}
                  </div>
                  <div class="m-auto-bokahem mt-1">
                    {{ giftcardSellerPhone }}
                  </div>
                  <div class="m-auto-bokahem mt-1 mb-1">
                    <a :href="`mailto:${giftcardSellerEmail}`">{{ giftcardSellerEmail }}</a>
                  </div>

                </fieldset>
              </div>
              <div class="col-bokahem-12 mt-2 text-center">
                I samarbete med <a class="block-text-link" href="http://smartapresentkort.se" target="_blank">SmartaPresentkort™</a>
              </div>
            </div>
            <SpkLogoSingle></SpkLogoSingle>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>

import SellerLogo from "@/pages/GiftcardSellForm/components/SellerLogo";
import SpkLogoSingle from "@/pages/GiftcardSellForm/components/SpkLogoSingle";

export default {
  components: {
    SellerLogo,
    SpkLogoSingle
  },
  computed: {
    giftcardSellerName() {
      return this.$store.getters.giftcardSellerName;
    },
    giftcardSellerPhone() {
      return this.$store.getters.giftcardSellerPhone;
    },
    giftcardSellerEmail() {
      return this.$store.getters.giftcardSellerEmail;
    },
    sessionFirstname() {
      return this.$store.getters.sessionFirstname;
    },
    sessionLastname() {
      return this.$store.getters.sessionLastname;
    },
    sessionEmail() {
      return this.$store.getters.sessionEmail;
    },
    sessionQuantity() {
      return this.$store.getters.sessionQuantity;
    },
    sessionAmount() {
      return this.$store.getters.sessionAmount;
    },
  },
  data() {
    return {
      firstname: null,
      lastname: null,
      email: null,
      amount: null
    }
  }
}

</script>