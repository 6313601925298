<template>
  <div class="modal-filter" id="modalFilter">
    <div class="modal-filter-inner">
      <div class="modal-filter-body" style="font-size: 14px !important;">
        <h2>Användarvillkor presentkort</h2>
        <div>
          <h3>{{ giftcardSellerName }}</h3>
          <p>Presentkortet har en giltighetstid som är angiven på presentkortet. Vid kortbetalning debiteras kortet direkt. Momsen är 0 kr.</p>
          <p>Hanteringen och betalflödet hanteras av SmartaPresentkort™ (drivs av About Time Tools AB, orgnr 559347-0551). När du köper ett presentkort
            så samlar SmartaPresentkort™ in de personuppgifter som är nödvändiga för att genomföra
            ett köp och hantera presentkortet. Uppgifterna delas med {{ giftcardSellerName }}.
            Uppgifterna kan också komma att användas för marknadskommunikation och verksamhetsutveckling.
            Själva betalningstransaktionen hanteras av Payer AB.  Under dataskyddsförordningen har du rätt att begära ett utdrag av dina uppgifter, rätta dem eller få dem raderade.

            Vill du komma i kontakt med oss angående dina personuppgifter ska du i första
            hand vända dig till företaget som är angiven på presentkortet.

            Vill du utnyttja din ångerrätt måste du informera företaget som är angivet på presentkortet inom 14 dagar.

            Läs mer i våra <a v-bind:style="{ color: buttonColor + ' !important'}"
                              href="https://www.smartapresentkort.se/anvandarvillkor-presentkort/" target="_blank">
              användarvillkor</a>.
          </p>
        </div>
        <button v-bind:style="{ backgroundColor: buttonColor + ' !important', color: buttonTextColor + ' !important'}" type="button" class="btn-bokahem btn-bokahem-primary-button text-button-color font-weight-semi-bokahem btn-bokahem-lg"
                @click="closeModal()">OK</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    closeModal() {
      this.$emit('close');
    }
  },
  computed: {
    giftcardSellerName() {
        return this.$store.getters.giftcardSellerName;
    },
    buttonColor() {
      return this.$store.getters.buttonColor;
    },
    buttonTextColor() {
      return this.$store.getters.buttonTextColor;
    },
  }
}
</script>