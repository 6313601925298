import {FETCH_PLUGIN_SETTINGS} from "@/store/actions.type";
import {SET_PLUGIN_SETTINGS} from "@/store/mutations.type";
import {PluginSettingsService} from "@/core/services/api";

const actions = {
    [FETCH_PLUGIN_SETTINGS]({ commit }) {
        return PluginSettingsService.get()
            .then(({ data }) => {
                commit(SET_PLUGIN_SETTINGS, data);
            })
            .catch(error => {
                commit(SET_PLUGIN_SETTINGS, []);
                return error;
            });
    },
}

export default actions;