<template>
  <div class="row-bokahem mt-3 mb-1">
    <div class="col-bokahem-12 m-auto text-center">
      <a href="https://smartapresentkort.se" target="_blank">
        <img :src="logo" class="smartapresentkort-logo" style="max-width:100px;" />
      </a>
      <img :src="payer" class="smartapresentkort-logo" style="max-width:100px;" />
      <img :src="master" class="smartapresentkort-logo" style="max-width:100px;" />
      <img :src="swish" class="smartapresentkort-logo" style="max-width:100px;" />
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    logo() {
      return 'https://www.smartapresentkort.se/wp-content/uploads/2021/11/logo.jpeg.png';
    },
    payer() {
      return 'https://www.smartapresentkort.se/wp-content/uploads/2021/11/Payer_160_70.png';
    },
    master() {
      return 'https://www.smartapresentkort.se/wp-content/uploads/2021/11/Visa-och-Mastercard-160_70.png';
    },
    swish() {
      return 'https://www.smartapresentkort.se/wp-content/uploads/2021/11/Swish_160_70.png';
    }
  }
}
</script>