import {SET_PAYMENT_FORM,SET_GIFTCARD_MULTIPLE_REQUEST,SET_GIFTCARD_SESSION} from "@/store/mutations.type";

const mutations = {
    [SET_PAYMENT_FORM](state, paymentForm) {
        state.paymentForm = paymentForm;
    },
    [SET_GIFTCARD_SESSION](state, giftcardSession) {
        state.giftcardSession = giftcardSession;
    },
    [SET_GIFTCARD_MULTIPLE_REQUEST](state, giftcardMultipleRequest) {
        state.giftcardMultipleRquest = giftcardMultipleRequest;
    }
}

export default mutations;