import Vue from 'vue';
import App from './app.vue';
import VModal from 'vue-js-modal';
import Vue2TouchEvents from 'vue2-touch-events';
import vueCustomElement from 'vue-custom-element';
import 'document-register-element/build/document-register-element';
import VueRouter from "vue-router";
import Vuelidate from 'vuelidate';
import VueScrollTo from 'vue-scrollto';

import store from "./store";
import ApiService from "./core/services/api";
import routes from '@/core/services/router';

Vue.use(VueRouter);
Vue.use(Vuelidate);
Vue.use(VueScrollTo);

Vue.config.productionTip = false;
App.store = store;


Vue.use(vueCustomElement);
Vue.use(Vue2TouchEvents);

App.router = new VueRouter({
  mode: "history",
  routes: routes
})

ApiService.init();

Vue.use(VModal, { dynamic: true, injectModalsContainer: true });

Vue.customElement('spk-plugin', App);