const getters = {
    paymentForm(state) {
        return state.paymentForm;
    },
    sessionFirstname(state) {
        if ('firstname' in state.giftcardSession) {
            return state.giftcardSession.firstname;
        } else {
            return null;
        }
    },
    sessionLastname(state) {
        if ('lastname' in state.giftcardSession) {
            return state.giftcardSession.lastname;
        } else {
            return null;
        }
    },
    sessionEmail(state) {
        if ('email' in state.giftcardSession) {
            return state.giftcardSession.email;
        } else {
            return null;
        }
    },
    sessionAmount(state) {
        if ('amount' in state.giftcardSession) {
            return state.giftcardSession.amount;
        } else {
            return null;
        }
    },
    sessionQuantity(state) {
        if ('quantity' in state.giftcardSession) {
            return state.giftcardSession.quantity;
        } else {
            return null;
        }
    },
    giftcardMultipleRequest(state) {
        return state.giftcardMultipleRequest;
    },
}

export default getters;