const getters = {
    pluginSettings(state) {
        return state.pluginSettings;
    },
    origin(state) {
        if ('origin' in state.pluginSettings) {
            return state.pluginSettings.origin;
        } else {
            return null;
        }
    },
    buttonColor(state) {
        if ('setting_button_color' in state.pluginSettings) {
            return state.pluginSettings.setting_button_color;
        } else {
            return null;
        }
    },
    buttonTextColor(state) {
        if ('setting_button_text_color' in state.pluginSettings) {
            return state.pluginSettings.setting_button_text_color;
        } else {
            return null;
        }
    },
    checkboxBorderColor(state) {
        if ('setting_checkbox_border_color' in state.pluginSettings) {
            return state.pluginSettings.setting_checkbox_border_color;
        } else {
            return null;
        }
    },
    showSellerLogo(state) {
        if ('setting_show_seller_logo' in state.pluginSettings) {
            return state.pluginSettings.setting_show_seller_logo;
        } else {
            return null;
        }
    },
    showInterestForm(state) {
        if ('show_interest_form' in state.pluginSettings) {
            return state.pluginSettings.show_interest_form;
        } else {
            return null;
        }
    },
    headingTitle(state) {
        if ('heading_title_sell' in state.pluginSettings) {
            return state.pluginSettings.heading_title_sell;
        } else {
            return null;
        }
    },
    logo(state) {
        if ('giftcard_seller' in state.pluginSettings
            && 'logo_link' in state.pluginSettings.giftcard_seller) {
            return state.pluginSettings.giftcard_seller.logo_link;
        } else {
            return null;
        }
    },
    giftcardImage(state) {
        if ('giftcard_seller' in state.pluginSettings
            && 'giftcard_image_link' in state.pluginSettings.giftcard_seller) {
            return state.pluginSettings.giftcard_seller.giftcard_image_link;
        } else {
            return null;
        }
    },
    giftcardSellerName(state) {
        if ('giftcard_seller' in state.pluginSettings
            && 'name' in state.pluginSettings.giftcard_seller) {
            return state.pluginSettings.giftcard_seller.name;
        } else {
            return null;
        }
    },
    giftcardSellerPhone(state) {
        if ('giftcard_seller' in state.pluginSettings
            && 'company_phone' in state.pluginSettings.giftcard_seller) {
            return state.pluginSettings.giftcard_seller.company_phone;
        } else {
            return null;
        }
    },
    giftcardSellerEmail(state) {
        if ('giftcard_seller' in state.pluginSettings
            && 'company_email' in state.pluginSettings.giftcard_seller) {
            return state.pluginSettings.giftcard_seller.company_email;
        } else {
            return null;
        }
    },
    secondsBeforeRedirect(state) {
        if ('seconds_before_redirect' in state.pluginSettings) {
            return state.pluginSettings.seconds_before_redirect;
        } else {
            return null;
        }
    },
    externalRedirectLink(state) {
        if ('external_redirect_link' in state.pluginSettings) {
            return state.pluginSettings.external_redirect_link;
        } else {
            return null;
        }
    },
}

export default getters;