import GiftcardSellForm from "@/pages/GiftcardSellForm";

const routes = [
    {
        path: '/',
        name: 'GiftcardSellForm',
        component: GiftcardSellForm
    },
]

export default routes;