<template>
  <div class="container-bokahem">
    <div class="calendar-bokahem">
      <div class="calendar-bokahem-body">
        <div class="row-bokahem">
          <div class="col-bokahem-12">
            <fieldset class="form-group-bokahem check_border_bokahem" v-if="sent == false">
              <div class="m-auto-bokahem mt-1 text-center-bokahem">
                Fyll i nedan information och vi kontaktar er, normalt sätt, inom två arbetstimmar.
              </div>

              <div class="bokahem-field-name-block">
                <span v-if="giftcardRequest.company_name || (submitted && !$v.giftcardRequest.company_name.required)">Företagsnamn</span>
                <span class="smartapresentkort-valid-error" v-if="submitted && !$v.giftcardRequest.company_name.required">
                      krävs
                    </span>
              </div>
              <div class="info-input-bokahem-holder">
                <input type="text" v-model="giftcardRequest.company_name" placeholder="Företagsnamn" class="info-block-bokahem-input border-rounded-bokahem font-weight-medium-bokahem">
              </div>

              <div class="bokahem-field-name-block">
                <span v-if="giftcardRequest.organisation_number || (submitted && !$v.giftcardRequest.organisation_number.required)">Orgnr</span>
                <span class="smartapresentkort-valid-error" v-if="submitted && !$v.giftcardRequest.organisation_number.required">
                      krävs
                    </span>
              </div>
              <div class="info-input-bokahem-holder">
                <input type="text" v-model="giftcardRequest.organisation_number" placeholder="Orgnr"
                       class="info-block-bokahem-input border-rounded-bokahem font-weight-medium-bokahem">
              </div>

              <div class="bokahem-field-name-block">
                <span v-if="giftcardRequest.contact_person || (submitted && !$v.giftcardRequest.contact_person.required)">
                  Kontaktperson
                </span>
                <span class="smartapresentkort-valid-error" v-if="submitted && !$v.giftcardRequest.contact_person.required">
                      krävs
                    </span>
              </div>
              <div class="info-input-bokahem-holder mb-1">
                <input type="text" v-model="giftcardRequest.contact_person" placeholder="Kontaktperson" class="info-block-bokahem-input border-rounded-bokahem font-weight-medium-bokahem">
              </div>


              <div class="bokahem-field-name-block">
                <span v-if="giftcardRequest.email || (submitted && !$v.giftcardRequest.email.required)">E-post</span>
                <span class="smartapresentkort-valid-error" v-if="submitted && !$v.giftcardRequest.email.required">
                      krävs
                    </span>
                <span class="smartapresentkort-valid-error" v-if="submitted && !$v.giftcardRequest.email.email">
                      är inte giltig
                    </span>
              </div>
              <div class="info-input-bokahem-holder mb-1">
                <input type="text" v-model="giftcardRequest.email" placeholder="E-post" class="info-block-bokahem-input border-rounded-bokahem font-weight-medium-bokahem">
              </div>


              <div class="bokahem-field-name-block">
                <span v-if="giftcardRequest.phone || (submitted && !$v.giftcardRequest.phone.required)">Phone</span>
                <span class="smartapresentkort-valid-error" v-if="submitted && !$v.giftcardRequest.phone.required">
                      krävs
                    </span>
              </div>
              <div class="info-input-bokahem-holder mb-1">
                <input type="text" v-model="giftcardRequest.phone" placeholder="Mobilnummer" class="info-block-bokahem-input border-rounded-bokahem font-weight-medium-bokahem">
              </div>


              <div class="bokahem-field-name-block">
                <span v-if="giftcardRequest.quantity || (submitted && !$v.giftcardRequest.quantity.required)">
                  Antal presentkort som ni vill ha
                </span>
                <span class="smartapresentkort-valid-error" v-if="submitted && !$v.giftcardRequest.quantity.required">
                      krävs
                    </span>
              </div>
              <div class="info-input-bokahem-holder mb-1">
                <input type="text" v-model="giftcardRequest.quantity" placeholder="Antal presentkort som ni vill ha" class="info-block-bokahem-input border-rounded-bokahem font-weight-medium-bokahem">
              </div>


              <div class="bokahem-field-name-block">
                <span v-if="giftcardRequest.amount || (submitted && !$v.giftcardRequest.amount.required)">Belopp på presentkortet</span>
                <span class="smartapresentkort-valid-error" v-if="submitted && !$v.giftcardRequest.amount.required">
                      krävs
                    </span>
              </div>
              <div class="info-input-bokahem-holder mb-1">
                <input type="text" v-model="giftcardRequest.amount" placeholder="Belopp på presentkortet" class="info-block-bokahem-input border-rounded-bokahem font-weight-medium-bokahem">
              </div>


              <div class="bokahem-field-name-block">
                <span v-if="giftcardRequest.comment || submitted">Speciella önskemål</span>
              </div>
              <div class="info-input-bokahem-holder mb-1">
                <textarea rows="6" v-model="giftcardRequest.comment" placeholder="Speciella önskemål" class="textarea-bokahem info-block-bokahem-input border-rounded-bokahem font-weight-medium-bokahem">
                </textarea>
              </div>

            </fieldset>

            <fieldset v-if="sent == true">
              <div class="m-auto-bokahem mt-1 text-center-bokahem">
                Tack. Vi kommer att kontakta dig.
              </div>
            </fieldset>

          </div>

          <div class="col-bokahem-12 mt-1" v-if="sent == false">
            <button
                v-bind:style="{ backgroundColor: buttonColor + ' !important', color: buttonTextColor + ' !important'}"
                @mouseover="hover = true"
                @mouseleave="hover = false"
                :class="{ 'btn-hover': hover }"
                @click="sendForm()" class="btn-bokahem btn-bokahem-primary text-button-color text-white border-rounded-bokahem btn-bokahem-lg font-weight-semi-bokahem">
              Skicka
            </button>
          </div>

          <div class="col-bokahem-12 mt-2 text-center">
            I samarbete med <a class="block-text-link" href="http://smartapresentkort.se" target="_blank">SmartaPresentkort™</a>
          </div>

        </div>
        <SpkLogo></SpkLogo>
      </div>
    </div>
  </div>
</template>

<script>

import { CREATE_GIFTCARD_MULTIPLE_REQUEST } from "@/store/actions.type";
import { required, email, integer, maxValue, minValue } from 'vuelidate/lib/validators';
import SpkLogo from "@/pages/GiftcardSellForm/components/SpkLogo";

export default {
  data() {
    return {
      giftcardRequest: {
        company_name: '',
        contact_person: '',
        email: '',
        phone: '',
        organisation_number: '',
        comment: '',
        amount: '',
        quantity: ''
      },
      submitted: false,
      sent: false
    }
  },
  validations: {
    giftcardRequest: {
      email: {
        email, required
      },
      phone: {
        required
      },
      company_name: {
        required
      },
      contact_person: {
        required
      },
      organisation_number: {
        required
      },
      amount: {
        required,
        integer,
        maxValue: maxValue(20000),
        minValue: minValue(1)
      },
      quantity: {
        required,
        integer,
        minValue: minValue(25)
      },
    }
  },
  computed: {
    buttonColor() {
      return this.$store.getters.buttonColor;
    },
    buttonTextColor() {
      return this.$store.getters.buttonTextColor;
    },
    checkboxBorderColor() {
      return this.$store.getters.checkboxBorderColor;
    },
    showSellerLogo() {
      return this.$store.getters.showSellerLogo;
    },
    giftcardSellerName() {
      return this.$store.getters.giftcardSellerName;
    }
  },
  components: {
    SpkLogo
  },
  methods: {
    sendForm() {

      this.submitted = true;

      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      let params = {};

      params['contact_person'] = this.giftcardRequest.contact_person;
      params['company_name'] = this.giftcardRequest.company_name;
      params['email'] = this.giftcardRequest.email;
      params['phone'] = this.giftcardRequest.phone;
      params['amount'] = this.giftcardRequest.amount;
      params['quantity'] = this.giftcardRequest.quantity;
      params['organisation_number'] = this.giftcardRequest.organisation_number;
      params['comment'] = this.giftcardRequest.comment;

      this.$store.dispatch(CREATE_GIFTCARD_MULTIPLE_REQUEST, params);

      this.sent = true;
    }
  }
}
</script>
