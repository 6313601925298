<template>
  <main>
    <div class="container-bokahem">
      <div class="calendar-bokahem mb-4">
        <div class="calendar-bokahem-body">
          <div>
            <SellerLogo></SellerLogo>
            <div class="row-bokahem">
              <div class="col-bokahem-12 mt-1">
                <fieldset class="form-group-bokahem check_border_bokahem">
                  <legend class="font-weight-medium-bokahem mb-0">
                    Välj belopp
                  </legend>

                <div class="col-bokahem-12 label-group-list-bokahem text-black-bokahem font-weight-medium-bokahem">

                  <div class="row-bokahem">
                    <div class="col-bokahem-xs-12 col-bokahem-sm-12 mb-1" style="width:97px;">
                      <label class="common-checkbox-label-bokahem"
                             :class="{'common-radio-label_active': giftcard.amount == 500 }">
                        <input v-model="giftcard.amount" value="500" type="radio" name="rutavdrag" class="common-radio">
                        <span class="common-custom-radio" v-bind:style="{ borderColor: checkboxBorderColor + ' !important'}">
                                      <span class="common-custom-radio_after"></span>
                                  </span>
                        500:-
                      </label>
                    </div>

                  <div class="col-bokahem-xs-12 col-bokahem-sm-12 mb-1" style="width:97px;">
                    <label class="common-checkbox-label-bokahem"
                           :class="{'common-radio-label_active': giftcard.amount == 1000 }">
                      <input v-model="giftcard.amount" value="1000" type="radio" name="rutavdrag" class="common-radio">
                      <span class="common-custom-radio" v-bind:style="{ borderColor: checkboxBorderColor + ' !important'}">
                                    <span class="common-custom-radio_after"></span>
                                </span>
                      1 000:-
                    </label>
                    </div>

                  <div class="col-bokahem-xs-12 col-bokahem-sm-12 mb-1" style="width:97px;">
                    <label class="common-checkbox-label-bokahem"
                           :class="{'common-radio-label_active': giftcard.amount == 2000 }">
                      <input v-model="giftcard.amount" value="2000" type="radio" name="rutavdrag" class="common-radio">
                      <span class="common-custom-radio" v-bind:style="{ borderColor: checkboxBorderColor + ' !important'}">
                                    <span class="common-custom-radio_after"></span>
                                </span>
                      2 000:-
                    </label>
                    </div>

                  <div class="col-bokahem-xs-12 col-bokahem-sm-12 mb-1" style="width:97px;">
                    <label class="common-checkbox-label-bokahem"
                           :class="{'common-radio-label_active': giftcard.amount == 3000 }">
                      <input v-model="giftcard.amount" value="3000" type="radio" name="rutavdrag" class="common-radio">
                      <span class="common-custom-radio" v-bind:style="{ borderColor: checkboxBorderColor + ' !important'}">
                                    <span class="common-custom-radio_after"></span>
                                </span>
                      3 000:-
                    </label>
                    </div>

                  <div class="col-bokahem-xs-12 col-bokahem-sm-12 mb-1" style="width:97px;">
                    <label class="common-checkbox-label-bokahem"
                           :class="{'common-radio-label_active': giftcard.amount == 5000 }">
                      <input v-model="giftcard.amount" value="5000" type="radio" name="rutavdrag" class="common-radio">
                      <span class="common-custom-radio" v-bind:style="{ borderColor: checkboxBorderColor + ' !important'}">
                                    <span class="common-custom-radio_after"></span>
                                </span>
                      5 000:-
                    </label>
                    </div>
                    </div>
                </div>

                  <div class="bokahem-field-name-block">
                    <span>Ange belopp</span>
                    <span class="smartapresentkort-valid-error" v-if="submitted && !$v.giftcard.amount.required">
                      krävs
                    </span>
                    <span class="smartapresentkort-valid-error" v-if="submitted
                    && !$v.giftcard.amount.integer">
                      ska vara ett heltal
                    </span>
                    <span class="smartapresentkort-valid-error" v-if="submitted
                    && $v.giftcard.amount.integer && (!$v.giftcard.amount.maxValue || !$v.giftcard.amount.minValue)">
                      min är 1, max är 20000
                    </span>
                  </div>
                  <div class="info-input-bokahem-holder mb-2">
                    <input v-model="giftcard.amount" type="tel" placeholder="Ange belopp" class="info-block-bokahem-input border-rounded-bokahem font-weight-medium-bokahem">
                  </div>

                  <div class="bokahem-field-name-block mt-1">
                    <span>Antal</span>
                    <span class="smartapresentkort-valid-error" v-if="submitted && !$v.giftcard.quantity.required">
                      krävs
                    </span>
                    <span class="smartapresentkort-valid-error" v-if="submitted
                    && !$v.giftcard.quantity.integer">
                      ska vara ett heltal
                    </span>
                    <span class="smartapresentkort-valid-error" v-if="submitted
                    && $v.giftcard.quantity.integer && (!$v.giftcard.quantity.maxValue || !$v.giftcard.quantity.minValue)">
                      min är 1, max är 25
                    </span>
                  </div>
                  <div class="info-input-bokahem-holder mb-2">
                    <input v-model="giftcard.quantity" type="tel" placeholder="Antal" class="info-block-bokahem-input border-rounded-bokahem font-weight-medium-bokahem">
                  </div>

                </fieldset>
              </div>

              <div class="col-bokahem-12">
                <fieldset class="form-group-bokahem check_border_bokahem">
                  <legend class="font-weight-medium-bokahem mb-0">
                    Leverans och kvitto
                  </legend>
                  <div class="m-auto-bokahem mt-1 text-center-bokahem" >
                    Ange ditt namn och din e-post dit presentkortet ska skickas
                  </div>

                  <div class="bokahem-field-name-block">
                    <span>Förnamn</span>
                    <span class="smartapresentkort-valid-error" v-if="submitted && !$v.giftcard.first_name.required">
                      krävs
                    </span>
                    <span style="font-size:11px;font-weight: normal; display: inline-block;float:right;padding-top:2px;">skrivs inte ut på presentkortet</span>
                  </div>

                  <div class="info-input-bokahem-holder">
                    <input type="text" v-model="giftcard.first_name" placeholder="Förnamn" class="info-block-bokahem-input border-rounded-bokahem font-weight-medium-bokahem">
                  </div>

                  <div class="bokahem-field-name-block">
                    <span>Efternamn</span>
                    <span class="smartapresentkort-valid-error" v-if="submitted && !$v.giftcard.last_name.required">
                      krävs
                    </span>
                    <span style="font-size:11px;font-weight: normal; display: inline-block;padding-top:2px;float:right;">skrivs inte ut på presentkortet</span>
                  </div>

                  <div class="info-input-bokahem-holder">
                    <input type="text" v-model="giftcard.last_name" placeholder="Efternamn" class="info-block-bokahem-input border-rounded-bokahem font-weight-medium-bokahem">
                  </div>

                  <div class="bokahem-field-name-block">
                    <span>E-post</span>
                    <span class="smartapresentkort-valid-error" v-if="submitted && !$v.giftcard.email.required">
                      krävs
                    </span>
                    <span class="smartapresentkort-valid-error" v-if="submitted && !$v.giftcard.email.email">
                      är inte giltig
                    </span>
                  </div>

                  <div class="info-input-bokahem-holder mb-1">
                    <input type="text" v-model="giftcard.email" placeholder="E-post" class="info-block-bokahem-input border-rounded-bokahem font-weight-medium-bokahem">
                  </div>
                </fieldset>

              </div>

              <div class="col-bokahem-12">
                <fieldset class="form-group-bokahem check_border_bokahem">
                  <legend class="font-weight-medium-bokahem mb-0">
                    Betalning och villkor
                    <span class="smartapresentkort-valid-error" v-if="submitted && agreed === false">
                      bör godkännas
                    </span>
                  </legend>

                  <div class="checkbox-label-row-bokahem mt-1 mb-1">
                    <label class="common-checkbox-label-bokahem" :class="{'common-checkbox-label-bokahem_active': agreed === true}">
                      <input type="checkbox" id="deal" class="common-checkbox">
                      <span class="common-custom-checkbox" v-bind:style="{ borderColor: checkboxBorderColor + ' !important'}"  @click="setApproveAgreement()">
                        <span class="common-custom-checkbox_after"></span>
                      </span>
                    </label>
                    <label for="deal" class="deal-label-text checkbox-label_col-bokahemor font-weight-medium-bokahem">
                      Jag godkänner <span class="text-underline" @click="showAgreementModal()">villkoren</span>
                    </label>
                  </div>
                </fieldset>
              </div>

              <div class="col-bokahem-12 mt-1">

                <div v-if="paymentError === true">
                  <div class="m-auto-bokahem mt-1 text-center-bokahem smartapresentkort-valid-error">
                      Det blev tyvärr ett fel så att betalningen inte kunde genomföras!
                  </div>

                  <div class="m-auto-bokahem mt-1 text-center-bokahem mb-1">
                    Försök rätta till felet och försök igen, eller kontakta oss på {{ giftcardSellerName }}.
                  </div>
                </div>

                <button
                    v-bind:style="{ backgroundColor: buttonColor + ' !important', color: buttonTextColor + ' !important'}"
                    @mouseover="hover = true"
                    @mouseleave="hover = false"
                    :class="{ 'btn-hover': hover }"
                    @click="sendForm()" class="btn-bokahem btn-bokahem-primary text-button-color text-white border-rounded-bokahem btn-bokahem-lg font-weight-semi-bokahem">
                  Slutför köp
                </button>
              </div>

              <div class="col-bokahem-12 mt-2 text-center">
                I samarbete med <a class="block-text-link" href="http://smartapresentkort.se" target="_blank">SmartaPresentkort™</a>
              </div>
            </div>

            <SpkLogo></SpkLogo>

          </div>
        </div>
      </div>

      <form :ref="paymentFormContainer" method="POST" action="https://secure.payer.se/PostAPI_V1/InitPayFlow">

      </form>

    </div>
  </main>
</template>

<script>

import { CREATE_GIFTCARD } from "@/store/actions.type";
import { required, email, integer, maxValue, minValue } from 'vuelidate/lib/validators';
import agreement from "@/pages/GiftcardSellForm/components/agreement";
import SellerLogo from "@/pages/GiftcardSellForm/components/SellerLogo";
import SpkLogo from "@/pages/GiftcardSellForm/components/SpkLogo";

export default {
  components: {
    SellerLogo,
    SpkLogo
  },
  data() {
    return {
      paymentFormContainer: 'payment-form-container',
      giftcard: {
        first_name: '',
        last_name: '',
        email: '',
        amount: '',
        quantity: 1
      },
      submitted: false,
      agreed: false,
      paymentSuccess: null
    }
  },
  validations: {
    giftcard: {
      email: {
        email, required
      },
      first_name: {
        required
      },
      last_name: {
        required
      },
      amount: {
        required,
        integer,
        maxValue: maxValue(20000),
        minValue: minValue(1)
      },
      quantity: {
        required,
        integer,
        maxValue: maxValue(25),
        minValue: minValue(1)
      }
    }
  },
  computed: {
    buttonColor() {
      return this.$store.getters.buttonColor;
    },
    buttonTextColor() {
      return this.$store.getters.buttonTextColor;
    },
    checkboxBorderColor() {
      return this.$store.getters.checkboxBorderColor;
    },
    showSellerLogo() {
      return this.$store.getters.showSellerLogo;
    },
    giftcardSellerName() {
      return this.$store.getters.giftcardSellerName;
    }
  },
  props: {
    'paymentError': Boolean
  },
  methods: {
    setApproveAgreement() {
      if (this.agreed == true) {
        this.agreed = false;
      } else {
        this.agreed = true;
      }
    },
    showAgreementModal() {
      this.$modal.show(agreement, {},{
        width: '90%',
        maxWidth: 500,
        adaptive: true,
        scrollable: true,
        height: 'auto',
        minHeight:400
      });
    },
    sendForm() {
      this.submitted = true;

      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      if (this.agreed === false) {
        return;
      }

      let params = {};

      params['first_name'] = this.giftcard.first_name;
      params['last_name'] = this.giftcard.last_name;
      params['email'] = this.giftcard.email;
      params['amount'] = this.giftcard.amount;
      params['quantity'] = this.giftcard.quantity;

      this.$store.dispatch(CREATE_GIFTCARD, params);
    }
  },
  watch: {
    '$store.state.giftcards.paymentForm': function () {
      if ('form_action' in this.$store.state.giftcards.paymentForm
          && 'form_content' in this.$store.state.giftcards.paymentForm) {
          this.$refs[this.paymentFormContainer].innerHTML = this.$store.state.giftcards.paymentForm.form_content;
          const form = this.$refs[this.paymentFormContainer];
          form.submit();
      }
    }
  }
}

</script>