import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { API_URL } from "@/core/config";

const ApiService = {
    init() {
        Vue.use(VueAxios, axios);
        Vue.axios.defaults.baseURL = API_URL;

        let url = location.href;
        let urlParts = url.split('?');
        let urlWithoutQueryParameters = urlParts[0];

        Vue.axios.defaults.headers.common['placement'] = urlWithoutQueryParameters;
    },
    get(resource, slug = "") {
        return Vue.axios.get(`${resource}${slug}`).catch(error => {
            throw new Error(`[RWV] ApiService ${error}`);
        });
    },
    post(resource, params) {
        return Vue.axios.post(`${resource}`, params);
    }
};

export default ApiService;

/**
 *
 * @type {{get(): *}}
 */
export const PluginSettingsService = {
    get() {
        return ApiService.get('giftcardsellers/plugin', '');
    }
};

/**
 *
 * @type {{get(): *}}
 */
export const CreateGiftcardService = {
    get(params) {
        return ApiService.post('giftcards/payer/generate-payment-form', params);
    }
};

/**
 *
 * @type {{get(): *}}
 */
export const CreateGiftcardMultiRequestService = {
    get(params) {
        return ApiService.post('giftcards/interest', params);
    }
};

/**
 *
 * @type {{get(*): *}}
 */
export const FetchPayerSessionService = {
    get(token) {
        let query = '?token=' + token
        return ApiService.get('giftcards/session', query);
    }
};
