import Vue from 'vue';
import Vuex from 'vuex';
import pluginSettings from '@/store/modules/pluginSettings';
import giftcards from '@/store/modules/giftcards';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        pluginSettings,
        giftcards
    }
})